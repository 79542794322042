<template>
  <span class="de-avatar" :class="[`de-avatar-${size}`, { 'de-avatar-circle': isCircle }]">
    <span v-if="label" class="de-avatar-label">{{ label }}</span>
    <img
      v-else-if="showImage"
      :src="src"
      :alt="alt"
      :loading="loading"
      class="de-avatar-image"
      @error="onLoadError"
    />
    <de-svg-icon v-else name="user-filled" class="de-avatar-icon-default" :class="iconClass" />
  </span>
</template>

<script setup lang="ts">
import { computed, ref } from '#imports';
import type { AvatarShape, AvatarSize } from '~/shared/components/lib/avatar/avatar.interface';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';
import {
  AVATAR_SHAPE_OPTIONS,
  AVATAR_SIZE_OPTIONS,
} from '~/shared/components/lib/avatar/avatar.constant';

const props = withDefaults(
  defineProps<{
    src?: string | null;
    size?: AvatarSize;
    label?: string;
    alt?: string;
    loading?: 'lazy' | 'eager';
    fallbackOnError?: boolean;
    shape?: AvatarShape;
    iconClass?: string;
  }>(),
  {
    size: AVATAR_SIZE_OPTIONS.normal,
    loading: 'eager',
    shape: AVATAR_SHAPE_OPTIONS.circle,
  },
);

const imageLoadError = ref(false);

const showImage = computed(() => {
  // Don't show when image is not present
  if (!props.src) {
    return false;
  }
  // Don't show when fallbackOnError is true and there was failure to load image
  if (props.src && props.fallbackOnError && imageLoadError.value) {
    return false;
  }

  return true;
});

const isCircle = computed(() => {
  return props.shape === AVATAR_SHAPE_OPTIONS.circle;
});

const emit = defineEmits(['load-error']);
const onLoadError = (event: Event) => {
  imageLoadError.value = true;
  emit('load-error', event);
};
</script>

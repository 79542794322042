export const AVATAR_SIZE_OPTIONS = {
  normal: 'normal',
  small: 'sm',
  medium: 'md',
  large: 'lg',
  custom: 'custom',
} as const;

export const AVATAR_SHAPE_OPTIONS = {
  circle: 'circle',
  rect: 'rect',
};
